import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import CardGeneral from "./card-general";
import { Check, CheckCircle } from "react-feather";
import TTPro from  "./sidebars/ttpro.svg"
import Button from "./atoms/Button";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { navigate } from "gatsby";
import { BadgeCheck, ListOrdered, Smile, Sparkles, Users2 } from "lucide-react";
import RoofingCoupon from "./sidebars/siding-coupon";

const WhyUs = () => {
  const [claimingInProgress, setClaimingInProgress] = useState(false);

  const [offer, setOffer] = useLocalStorage("offers", {});

  const claimOffer = () => {
    setOffer({ offer: "$50 OFF Tesla Wall Charger Installation", expires: "" });
    setClaimingInProgress(true);
    setTimeout(() => {
      navigate("/contact/");
    }, 1000);
  };

  return (
    <StaticQuery
      query={graphql`
        query WhyUsListQuery {
          site {
            siteMetadata {
              whyUs
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
       
       <RoofingCoupon/>
            <div className="rounded-xl mb-2 shadow bg-white ">
              <div class="px-8 pt-8 pb-6 border-b">
                <h2 class="text-neutral-900 font-bold text-xl md:text-2xl font-display">Why Roach?</h2>
                <p class="text-neutral-600 font-normal text-sm md:text-base font-display">Highly rated roofing and home remodeling services</p>
              </div>
              <ul className="pb-10 pt-6 px-8 space-y-6 text-neutral-600">
                <li className="flex items-start">
                  {" "}
                  <ListOrdered size={24}   className="text-brand-700 shrink-0" />
                  <div className="ml-4">
                    <span className="font-semibold font-display text-neutral-800 block">No Hidden Costs, Free Quotes</span>
                    <p>Transparent, Up Front Pricing Without Surprises</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Users2 size={24}    className="text-brand-700 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-neutral-800 block">Family-Owned &amp; Operated</span>

                    <p>Michigan-Based Contractors</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <Sparkles size={24} className="text-brand-700 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-neutral-800 block">Experienced</span>

                    <p>20+ Years of Experience </p>
                  </div>
                </li>
            

                <li className="flex items-start">
                  <Smile size={24}    className="text-brand-700 shrink-0" />

                  <div className="ml-4">
                    <span className="font-semibold font-display text-neutral-800 block">Satisfaction Guarantee</span>

                    <p>We Offer a Satisfaction Guarantee on All Remodeling Work</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="rounded-xl mb-2 shadow bg-white">
              <div class="px-8 py-6 flex items-start space-x-4">
               
                <div>
                  <div className="flex items-center">
               
                <div className="flex-1">
                  <h3 class="text-neutral-900 font-bold text-lg md:text-xl font-display">Meet the <span className="text-brand-700">Roach Team</span></h3>
                  <h3 class="text-neutral-500 font-normal text-base font-display">The Roach Home Improvement Story</h3></div>

                  
                  </div>
        
                  <div className="flex -space-x-2 overflow-hidden py-3">
        <img
          className="inline-block h-14 w-14 rounded-full ring-4 ring-white object-cover position-center center image-center scale-100 hover:scale-110 transition duration-300 ease-in-out"
          src="https://images.ctfassets.net/dnje5q8u1u3a/5QCS38h4Np0yTDImWYJZsn/373acd21b6b7ea2f5304cc357cf50eb6/Terry_Roach-_Co-Owner.png?h=100"
          alt=""
        />
        <img
          className="inline-block h-14 w-14 rounded-full ring-4 ring-white object-cover position-center center image-center scale-100 hover:scale-110 transition duration-300 ease-in-out" 
          src="https://images.ctfassets.net/dnje5q8u1u3a/2HVS6PgWaRhwyrugFINQEe/93404a00f281220dd101a32d76f7c4c5/Shaun_Roach-_Co-_Owner_.png?h=100"
          alt=""
        />
        <img
          className="inline-block h-14 w-14 rounded-full ring-4 ring-white object-cover position-center center image-center scale-100 hover:scale-110 transition duration-300 ease-in-out"
          src="https://images.ctfassets.net/dnje5q8u1u3a/3386KsYlG3GtnHJQ7ztfYY/71825cddf44494cb5abcb811dd1bbb75/Kelesy_Cohoon-_communication_specialist.jpeg?h=100"
          alt=""
        />
        <img
          className="inline-block h-14 w-14 rounded-full ring-4 ring-white object-cover position-center center image-center scale-100 hover:scale-110 transition duration-300 ease-in-out"
          src="https://images.ctfassets.net/dnje5q8u1u3a/2M8Vua25KPR6cJKVeELb6F/f25939105c57fc15ecd2f1398ba3ef60/Rochelle_Roach-_Book_keeper.png?h=100"
          alt=""
        />
      </div>
                  <p className="text-neutral-500 font-display font-normal text-sm">
                  At Roach Home Improvement, we pride ourselves on delivering top-quality roofing solutions to homeowners in Battle Creek, Portage, Kalamazoo, and surrounding areas. With our extensive experience and commitment to excellence, we have become a trusted name in the roofing industry.


  </p>
                  <Button className="bg-white mt-4" to="/about/">
                   Learn More
                  </Button>
                </div>
              </div>
            </div>


 
            <div className="rounded-xl mb-2 shadow bg-white">
              <div class="px-8 py-6 flex items-start space-x-4">
                {/* <img
                  src="https://images.ctfassets.net/seqn6hyrh7p7/1YBBOd4h58mVjDFPWklph0/0b0634904698a95f1b53f337ce548401/Screen_Shot_2022-08-04_at_12.48.30_PM.png?h=250"
                  className="w-16 h-16 rounded-full border-2 border-white"
                /> */}
                <div>
                  <h3 class="text-neutral-900 font-bold text-lg md:text-xl font-display">Talk to our experts</h3>
                  <p className="text-neutral-500 font-display font-normal">
                    Free estimates, clear straightforward pricing
                  </p>
                  <Button className="bg-white mt-4" to="/contact/">
                    Get Pricing
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="rounded-xl mb-2 shadow bg-white mt-4 border-4 border-brand-100">
              <div class="px-8 py-6 flex items-center space-x-4">
                <img
                  src=" https://images.ctfassets.net/seqn6hyrh7p7/KhtI1D3NxmJaQZ1SdiXul/e041fdecc73b85da180569819df93db5/kindpng_4658234.png"
                  className="w-1/3"
                />
                <div>
                  <h3 class="text-brand-700 font-bold text-xl md:text-3xl font-display">$50 OFF</h3>
                  <p className="text-brand-700 font-display font-semibold text-lg ">Tesla Wall Charger Installation</p>
                
                  <Button className="mt-4" onClick={() => claimOffer()}>
                    {claimingInProgress ? (
                      <svg
                        class="animate-spin ml-3 mr-3 h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Claim"
                    )}
                  </Button>
                </div>
              </div>
            </div> */}
          </>
        );
      }}
    />
  );
};

export default WhyUs;
