import React from "react";
import styled from "styled-components";
import Reputation from "./reputation";
import WhyUs from "./whyus";
import ContactInfo from "./contact-info"; 
import CardGeneral from "./card-general";

import { StaticQuery, graphql } from "gatsby";
import HappyCustomers from "./sidebars/happy-customers";
import RoofingCoupon from "./sidebars/siding-coupon";
import ElitePreferredStatus from "./sidebars/elite-preferred-status";
import { Link } from "gatsby";
import AngiesListAward from "./sidebars/angieslist";
import BestofHouzz from "./sidebars/bestofhouzz";
import Button from "./atoms/Button";

const MainContainerOuter = styled.div`
  width: 100%;
  background: white;
  margin-bottom: 1em;
`;

const SidebarTitle = styled.div`
  background: #f6f6f6;
  padding: 1em;
  h3 {
    margin: 0;
  }
`;
const SidebarContent = styled.div`
  padding: 1em;
`;

const Card = ({ sidebar, children }) => {
  if (sidebar.content.content.includes("{{AwardsCerts}}")) {
    return null;
  }

  if (sidebar.content.content.includes("{{ReputationBar}}")) {
    return null;
    return (
      <CardGeneral title={"Find Us On"}>
        <Reputation />
        <Link
          className="button-style-primary  text-base font-semibold mt-4 rounded-full flex flex-col items-center justify-between col-span-1 md:col-span-2 lg:col-span-1 py-2 px-3 shadow-md hover:shadow-lg hover:bg-white hover:cursor-pointer cursor-pointer"
          to={"/reviews/all/"}
          state={{
            modal: true,
          }}
        >
          Read All Reviews
        </Link>
      </CardGeneral>
    );
  }
  if (sidebar.content.content.includes("{{Testimonials}}")) {
    return null;
    let limitTestimonialCategoryDisplayTo = null;
    if (sidebar.testimonialCategory) {
      limitTestimonialCategoryDisplayTo = sidebar.testimonialCategory.id;
    }
    return null;
  }
  if (sidebar.content.content.includes("{{WhyUs}}")) {
    return <WhyUs />;
  }
  if (sidebar.content.content.includes("{{ContactInfo}}")) {
    return <ContactInfo />;
  }
  if(sidebar.content.content.includes("{{Finacing}}")) {
    return(
      <CardGeneral className="border-t-4 border-brand-600 ">  
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-lg md:text-xl font-bold text-neutral-900">Financing Available!</h3>
          <p className="text-xs sm:text-sm text-neutral-600 text-center">We've partnered with reputable financial institutions to offer flexible financing options that suit your needs.</p>
        
        <div className="mt-3"> 
          <ul className="list-disc list-inside bg-white shadow rounded-xl p-6 mt-4 text-neutral-500 text-sm">
   <img src={'/assets/ally-bank1-df1707e95ab74faebbe7add87e48b853.png'} alt="Ally Bank" className="-my-2 mx-auto  block h-12" /> 
            <li>Long-Term Financing: Secure financing for up to 20 years.</li>
            <li>Instant Approvals: Receive initial full approvals in just seconds.</li>
            </ul>
        </div>
        <div className="mt-3">
        
          <ul className="list-disc list-inside bg-white shadow rounded-xl p-6 mt-4 text-neutral-500 text-sm">
            <img src={'/assets/cropped-michigan-saves-logo-1.png'} alt="Michigan Saves Financing"  className="mx-auto mb-2 block h-12" /> 
            <li>Quick and Easy Approvals: Get approved swiftly for loans up to $100,000 for up to 15 years.</li>
            </ul>
        </div>
        <Button className="mt-4" to={'/financing?ref=sidebar'}>Learn more</Button>
        </div>
      </CardGeneral>
    )
  }
  if (sidebar.content.content.includes("{{RoofingSpecialOffer}}")) {
    return <RoofingCoupon />;
  }
  if (sidebar.content.content.includes("{{BestofHouzz}}")) {
    return <BestofHouzz />;
  }
  if (sidebar.content.content.includes("{{AngiesListAward}}")) {
    return <AngiesListAward />;
  }
  if (sidebar.content.content.includes("{{SaganTeamPic}}")) {
    return null;
  }
  if (sidebar.content.content.includes("{{HappyCustomers}}")) {
    return <HappyCustomers />;
  }
  if (sidebar.content.content.includes("{{ElitePreferredStatus}}")) {
    return <ElitePreferredStatus />;
  }
  if (sidebar.content.content.includes("blog"))
    return (
      <MainContainerOuter
        className={
          "border-radius shadow-md sidebar-card  " + (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : "")
        }
      >
       
          <h3 className="text-neutral-900 font-bold px-8 pt-6 pb-2  text-lg md:text-xl font-display">{sidebar.title}</h3>
         
        <SidebarContent className="sidebarstyle">{children}</SidebarContent>
      </MainContainerOuter>
    );
  return (
    <MainContainerOuter
      className={
        "border-radius shadow-md sidebar-card " + (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : "")
      }
    >
      <SidebarTitle className="moduleheading">
        <h3>{sidebar.title}</h3>
      </SidebarTitle>
      <SidebarContent className="sidebarstyle" dangerouslySetInnerHTML={{ __html: sidebar.content.content }} />
    </MainContainerOuter>
  );
};

export default Card;
